<template>
  <div class="secondhead-text">About Mentor</div>
  <div class="profile_box mb-5">
    <div class="grid grid-cols-2 gap-1">
      <div>
        <div class="thirdhead-text">Prefix</div>
        <div class="mb-2 pl-3">
          {{ mentor_prefix ? mentor_prefix : "Not Specified" }}
        </div>
        <div class="thirdhead-text">Preferred Name</div>
        <div class="mb-2 pl-3">
          {{ nickname ? nickname : "None" }}
        </div>
        <div class="thirdhead-text">Suffix</div>
        <div class="mb-2 pl-3">
          {{ mentor_suffix ? mentor_suffix : "Not Specified" }}
        </div>
        <div class="thirdhead-text">Gender</div>
        <div class="mb-2 pl-3">
          {{ mentor_gender ? mentor_gender : "Not Specified" }}
        </div>

        <div class="thirdhead-text">Pronouns</div>
        <div class="mb-2 pl-3">
          {{ mentor_pronouns ? mentor_pronouns : "Not Specified" }}
        </div>

        <div class="thirdhead-text">Ethnicity</div>
        <div class="mb-2 pl-3">
          <ul v-if="mentor_ethnicity.length">
            <li v-for="(value, key) in mentor_ethnicity" :key="key" class="">
              {{ value.race }}
            </li>
          </ul>
          <div v-else>Not Specified</div>
        </div>
      </div>
      <div>
        <div class="thirdhead-text">Mentor Status</div>
        <div class="mb-2 pl-3">
          {{ profile_mentorStatus ? profile_mentorStatus : "Not Specified" }}
        </div>
        <div class="thirdhead-text">Preferred Academic Level</div>
        <div class="mb-2 pl-3">
          {{
            profile_preferedAcademicLevel
              ? getAcadLvl(profile_preferedAcademicLevel)
              : "Not Specified"
          }}
        </div>
        <div class="thirdhead-text">Is Alumni</div>
        <div class="mb-2 pl-3">
          {{ profile_isAlumni ? "Yes" : "No" }}
        </div>
        <div class="thirdhead-text" v-if="profile_isAlumni">Alumnus Year</div>
        <div class="mb-2 pl-3">{{ profile_alumnusYear }}</div>
        <div class="thirdhead-text">Practice Areas</div>
        <div class="mb-2 pl-3">
          <ul v-if="profile_practiceAreas.length > 0">
            <li
              v-for="(practice_area, key) in profile_practiceAreas"
              :key="key"
              class=""
            >
              {{ practice_area.description }}
            </li>
          </ul>
          <div v-else>None Specified</div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-2xl font-bold text-dark-purple mb-0">
    Contact Information
  </div>
  <div class="profile_box mb-5">
    <div class="mb-3">
      <div class="thirdhead-text mb-2">
        Email{{ emails.length > 1 ? "s" : "" }}
      </div>
      <div
        v-for="(email, key) in emails"
        :key="key"
        class="grid grid-cols-6 gap-1"
      >
        <div class="col-span-1">
          <a :href="'mailTo:' + email.email_address">
            <font-awesome-icon
              class="text-dark-purple"
              icon="envelope"
              aria-label="envelope icon to identify email section"
            />
          </a>
        </div>
        <div class="col-span-1">{{ email?.email_type }}</div>
        <div class="col-span-2 text-purple-link">
          {{ email?.email_address }}
        </div>
        <div class="col-span-1">
          {{ email?.is_primary === true ? "Primary" : "" }}
        </div>
        <div class="col-span-1">
          {{ email?.is_public === true ? "Visible" : "" }}
        </div>
      </div>
    </div>
    <div class="mb-3">
      <div class="thirdhead-text mb-2">
        Phone Number{{ phoneNumbers?.length > 1 ? "s" : "" }}
      </div>
      <div
        v-for="(phone, key) in phoneNumbers"
        :key="key"
        class="grid grid-cols-6 gap-1"
      >
        <div class="col-span-1">
          <a :href="'tel:' + phone.phone_number">
            <font-awesome-icon
              class="text-dark-purple"
              icon="phone"
              aria-label="phone icon to identify phone section"
            />
          </a>
        </div>
        <div class="col-span-1">{{ phone?.phone_type }}</div>
        <div class="col-span-2 text-purple-link col-span-2">
          {{ phone?.phone_number }}
        </div>
        <div class="col-span-1">
          {{ phone?.is_primary === true ? "Primary" : " " }}
        </div>
        <div class="col-span-1">
          {{ phone?.is_public === true ? "Visible" : " " }}
        </div>
      </div>
    </div>
    <div class="thirdhead-text mb-2">
      Address{{ addresses.length > 1 ? "es" : "" }}
    </div>

    <div
      v-for="address in addresses"
      :key="address.address_id"
      class="grid grid-cols-6 gap-1 mb-1"
    >
      <font-awesome-icon
        class="text-dark-purple"
        icon="building"
        aria-label="building icon to identify address section"
      />
      <div class="col-span-1">{{ address.address_type }}</div>
      <div class="col-span-2">
        <div class="mb-0">{{ address.address_line_one }}</div>
        <div class="mb-0">{{ address.address_line_two }}</div>
        <div class="mb-0">
          {{ address.city }} {{ address.state }} {{ address.zip_code }}
        </div>
      </div>
      <div class="col-span-1">
        {{ address.is_primary === true ? "Primary" : " " }}
      </div>
      <div class="col-span-1">
        {{ address.is_public === true ? "Visible" : " " }}
      </div>
    </div>
  </div>
  <div class="text-2xl font-bold text-dark-purple mb-0">Mentor Employer</div>
  <div class="profile_box">
    <div class="flex justify-between mb-5">
      <div>
        <div class="font-semibold text-dark-purple mb-1">
          {{ current_employer.employer_name }}
        </div>
        <div class="mb-2 pl-3">
          <font-awesome-icon
            class="text-dark-purple mr-1"
            icon="globe"
            aria-label="globe icon to identify website section"
          />
          <a
            v-if="current_employer.web_site"
            :href="current_employer.web_site"
            class="underline text-purple mb-0"
            target="_blank"
            >{{ current_employer.web_site }}
          </a>
          <span v-else>No Employer Website</span>
        </div>

        <div class="mb-2 pl-3">
          <font-awesome-icon
            class="text-dark-purple mr-1"
            icon="phone"
            aria-label="phone icon to identify phone section"
          />
          {{
            current_employer?.phone?.phone_number
              ? current_employer.phone.phone_number
              : "No Employer Phone Number"
          }}
        </div>

        <div class="flex justify-start pl-3">
          <font-awesome-icon
            class="text-dark-purple mr-1"
            icon="building"
            aria-label="building icon to identify address section"
          />
          <div v-if="current_employer?.address?.address_line_one">
            <div>
              {{ current_employer.address?.address_line_one }}
            </div>
            <div>
              {{ current_employer.address?.address_line_two }}
            </div>
            <div>
              {{ current_employer.address?.city }}
              {{ current_employer.address?.state }}
              {{ current_employer.address?.zip_code }}
            </div>
          </div>
          <div v-else>
            <p>No Employer Address</p>
          </div>
        </div>
      </div>
    </div>
    <div class="thirdhead-text">Position</div>
    <div class="pl-3">
      {{ employer_position ? employer_position : "Not Specified" }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MentorProfile",
  props: {
    mentor: {
      type: Object,
    },
  },
  data() {
    return {
      id: null,
      mentor_prefix: null,
      nickname: null,
      firstName: null,
      lastName: null,
      mentor_suffix: null,
      emails: [],
      phoneNumbers: [],
      addresses: [],
      mentor_gender: null,
      mentor_pronouns: null,
      mentor_ethnicity: [],

      mentorSince: null,

      //employment information
      employer_position: null,
      current_mentor_employer_id: null,
      current_mentor_employer_start_date: null,
      current_mentor_employer_end_date: null,
      current_employer: {
        employer_name: null,
        web_site: null,
        number_employees: null,
        display_employer: true,
        address: {
          address_id: null,
          address_type: "WORK",
          address_line_one: null,
          address_line_two: null,
          city: null,
          state: null,
          zip_code: null,
          is_primary: true,
          is_public: true,
        },
        phone: {
          phone_type: "WORK",
          phone_number: null,
          is_public: true,
          is_primary: true,
        },
      },

      //Mentor Profile
      profile_id: null,
      profile_practiceAreas: [],
      profile_mentorStatus: null,
      profile_alumnusYear: null,
      profile_christmasCards: false,
      profile_preferedAcademicLevel: null,
      profile_isAlumni: false,
    };
  },

  created() {
    this.mentor_prefix = this.mentor.prefix;
    this.nickname = this.mentor.nickname;
    this.firstName = this.mentor.firstname;
    this.lastName = this.mentor.lastname;
    this.mentor_suffix = this.mentor.suffix;
    this.mentor_pronouns = this.mentor.pronouns;
    this.mentor_gender = this.mentor.gender;
    this.mentor_ethnicity = this.mentor.ethnicity;

    this.emails = this.mentor.email;

    this.phoneNumbers = this.mentor.phone;

    this.addresses = this.mentor.address;

    this.mentorSince = this.mentor.added;

    // current employer
    if (this.mentor.employer.length > 0) {
      const current_employer = this.mentor.employer.find((emp) => emp.current);
      this.employer_position = current_employer?.job_title;
      this.current_mentor_employer_id = current_employer?.mentor_employer_id;
      this.current_mentor_employer_start_date = current_employer?.from_date;
      this.current_mentor_employer_end_date = current_employer.to_date;

      this.current_employer = current_employer?.employer;
    }
    //mentor profile
    const mentorProfile = this.mentor.profile;
    this.profile_id = mentorProfile.mentor_profile_id;
    this.profile_practiceAreas = [...mentorProfile.practice_areas].sort(
      (a, b) => a.priority - b.priority
    );
    this.profile_mentorStatus = this.mentor.mentor_status
      .split("_")
      .map(
        (word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()
      )
      .join(" ");
    this.profile_alumnusYear = mentorProfile.alumnus_year;
    if (this.profile_alumnusYear > 0) {
      //if alumni year is set then they are an alumni so setting the boolean value as such
      this.profile_isAlumni = true;
    }
    this.profile_christmasCards = mentorProfile.religious_greeting;
    this.profile_preferedAcademicLevel = mentorProfile.preferred_acad_level;
  },
  methods: {
    getAcadLvl(id) {
      return this.lookup_acad_levels.find((lvl) => lvl.acad_level_id === id)
        .level;
    },
  },
  computed: { ...mapGetters(["lookup_acad_levels"]) },
};
</script>

<style scoped></style>
