<template>
  <div>
    <div class="flex justify-between items-center">
      <div class="header-text pb-3" data-cy="header-admin-reports">
        <a href="javascript:void(0)" @click="$router.back()" title="go back">
          <font-awesome-icon icon="chevron-circle-left" />
        </a>
        {{ mentor_name ? mentor_name : "View Mentor" }}
      </div>
      <div v-if="isAdm() || isSra()">
        <router-link :to="`/admin/mentors/edit/${id}`">
          <Button text="Edit" />
        </router-link>
      </div>
    </div>
    <div class="shadow-sm bg-white rounded-md">
      <div v-if="loading" class="pt-5">
        <loading-spinner />
      </div>
      <div v-else class="box-style">
        <div class="flex flex-wrap w-full border-b border-cool-gray">
          <ul class="flex mb-0 list-none flex-wrap flex-row">
            <li
              class="-mb-px mr-2 last:mr-0 text-center"
              v-for="(tab, key) in tabs"
              :key="key"
            >
              <a
                href="javascript:void(0)"
                @click="openTab = key"
                class="border border-cool-gray rounded-t text-xl px-8 py-2 block leading-normal cursor-pointer"
                v-bind:class="{
                  'bg-whisper': openTab !== key,
                  '': openTab === key,
                }"
              >
                {{ tab.title }}
              </a>
            </li>
          </ul>
          <div
            v-if="openTab === 2"
            class="flex grow items-center px-3 justify-end"
          >
            <button
              class="rounded-full bg-purple hover:bg-dark-purple text-white py-1 px-2"
              v-text="'Add Note'"
              @click="$refs.note_input.editNote()"
            />
          </div>
        </div>
        <div class="tab-content tab-space px-5 py-5">
          <div v-if="!loading">
            <div v-if="openTab === 0"><mentor-profile :mentor="mentor" /></div>
            <div v-else-if="openTab === 1">
              <div>Pairings</div>
              <MentorPairingList
                :student_pairings="mentor.student_pairings"
                :surveys="mentor.surveys"
              />
            </div>
            <div v-else-if="openTab === 2">
              <add-edit-notes :mentor-id="id" ref="note_input" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "@/composables/httpUtil";
import { FormatDate } from "@/composables/date_utils";
import MentorProfile from "@/views/admin/mentors/mentor-views/MentorProfile";
import AddEditNotes from "@/views/admin/mentors/mentor-edits/AddEditNotes";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import Button from "@/components/reusable-components/base-level-components/Button";
import MentorPairingList from "@/views/admin/mentors/mentor-views/MentorPairingList";
import { isAdm, isSra } from "@/composables/tokenUtils";

export default {
  name: "AdminMentorDetail",
  components: {
    MentorPairingList,
    AddEditNotes,
    LoadingSpinner,
    MentorProfile,
    Button,
  },
  props: {
    _id: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      openTab: 0,
      tabs: [
        { title: "Profile" },
        { title: "Student Pairings" },
        { title: "Notes" },
      ],
      loading: false,

      mentor: null,
    };
  },
  created() {
    this.loading = true;
    this.id = this.$route.params.id ? this.$route.params.id : this._id;
    return this.get(`admin/mentors/${this.id}`)
      .then((results) => {
        this.mentor = results;
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    get,
    isAdm,
    isSra,
    FormatDate,
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
    },
    setup_mentor() {},
  },
  computed: {
    mentor_name() {
      let name = this.mentor
        ? `${this.mentor.firstname} ${
            this.mentor.middlename ? this.mentor.middlename : ""
          } ${this.mentor.lastname} ${
            this.mentor.nickname ? "(" + this.mentor.nickname + ")" : ""
          }`
        : false;
      if (name) {
        name = `${this.mentor.prefix ? this.mentor.prefix : ""} ${name} ${
          this.mentor.suffix ? this.mentor.suffix : ""
        }`;
      }
      return name;
    },
  },
};
</script>

<style scoped></style>
